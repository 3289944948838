.header{
    background-color: white;
    position: relative;
}
.header-left > img{
    width: 80%!important;
}
.header-right-up-d,.header-right-mid,.header-right-down{
    text-align: center;
}
.header-right-up-d > img{
    margin-top: 5.5%!important;
    width: 80%!important;
}
.header-right-mid{
     width: 70%!important;
     margin-left: 15%;
}
.header-right-mid > img{
    
    width: 80%!important;
}
.header-right-down{
    width: 80%!important;
    margin-left: 10%!important;
    cursor: pointer;
}
.imgcss
{
    width:100%;
}
.header-right-down > img{
    width: 80%!important;
    cursor: pointer;
    margin-bottom: 10px;
}
.intro{
    background-color: #ebd9cc;
    text-align: center;
}
.intro > img{
    width: 100vw;
}
.brands-intro > img{
    width: 100vw;
}
.old-vs-new{
    background-color: #fff2e4;
    text-align: center;
    position: relative;
    padding: 0 10vw;
}
.old-vs-new > img{
    width: 80vw;

}
.partner-profit{
    background: #ebd9cc;
}
.profit-margin > img{
    width: 70%!important;
    width: 65%!important;
}
.profit-margin{
    text-align: center;
}
.partners > img{
    width: 87%!important;
}
.partners{
    text-align: center;
    width:87%;
}
.faq{
    text-align: center;
    background-color: #fff2e4;
}
.faq > img{
    width:40%!important;
}
.faq-question{
    background-color: #fff2e4;
}
.questions{
    width:50%;
    margin: 0 25%;
    text-align: center!important;
    background-color: #fff2e4!important;
    font-style: normal!important;
}
.questions > img{
    width: 60%;
}
.footer{
    background-color: black;
    text-align: center  ;
}
.footer img{
    width: 100vh;
}
.accordion .accordion-header .accordion-button
{
    font-size: 1em;
    text-align: center!important;
}
p {
    width:  50%;
    margin: auto;
    font-family: 'Poppins', sans-serif;
  }
 /*  .modal{
      position: absolute;
      z-index:100;
      top:20%;
      left:20%;
      width:50%;
  } */
  .faqscss
{
    border: 1px solid rgba(235,217,204,.125);
    background-color: #fff2e4!important;
    /* color:#fff2e4; */
    font-family:'Poppins';
    text-align:center!important;
}
p.agree {
    width:  100%;
  }
  .accordion-button{
    display: block !important;;
    text-align: center!important;
}
.address{
    font-family:'Poppins'!important;
    font-size: 1em!important;
}

img#desktop-integrate
{
    cursor: pointer;
    position: absolute;
    left: 7.4vw;
    top: 60%;
    width: 30vw !important;
    z-index: 1;
}



img#desktop-animation
{
    position: absolute;
    top:-3%;
    left: 34.5%;
    width: 31vw !important;
}

.desktop-signup-btn
{
    margin-top: 2rem ;
    margin-bottom: 2rem;
}

.brands-peri-shop
{
    z-index: 1;
    width: 17%;
    height: 6%;
    left: 20.5%;
    top: 86.5%;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    cursor: pointer;
    border-bottom: 1px solid rgb(131, 131, 131);
}

.brands-peri-shop:hover
{
    border-bottom: 4px solid rgb(131, 131, 131);
}

.old-vs-new-2{
    background-color: #fff2e4;
    text-align: center;
    position: relative;
}
.old-vs-new-2 > img{
    width: 10vw;

}


.pink-bg
{
    background-color: rgb(199, 58, 58)!important;
}