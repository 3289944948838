.app-header{
    margin-top: 20px;
}
.appCapsule {
    width:70vw;
    padding: 56px 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: env(safe-area-inset-bottom);
    margin-top: env(safe-area-inset-top); 
    overflow: hidden;
}
.imgid{
    font-size: 9px;
    text-align: center;
    vertical-align: text-top;
}
.imaged {
    border-radius: 6px; 
    height:100%!important;
    width:100%!important;
}
.section {
    padding: 0 16px; }
    .section.full {
        padding: 0 5px 5px 0; }
.mb-2 {
    margin-bottom: 16px !important; }
.mt-2 {
    margin-top: 16px !important; }
.pe-2 {
    padding-right: 16px !important; }
.ps-2 {
    padding-left: 16px !important; }
.col-4{
    padding-left: 8px;
    padding-right: 8px; 
}
.profile-section{
    width: 100%;
    text-align: center;
    height: 215px;
    position: relative;
}
.profile-img{
    width: 150px !important;;
    height: 150px!important;
    border-radius: 50%;
    position: absolute;
    top:50%;
    left:50%;
    object-fit: cover!important;
    transform: translate(-50%,-50%);
}  
.appHeader{
    width:70%;
    margin:auto;
} 
.toggle-img{
    position: absolute;
    top: 3px;
    width:30px;
    height:30px;
    border-radius: 50%;
    background-color: white;
    opacity:0.8;
    right: 10%;
    z-index:100;
}
.toggle-img > span{
    margin-left:6px;
}
/*media css*/

.snap-switch{
    position: absolute;
    left: 1rem;
    top: 1rem;
    height: 1.5rem;
    width: 3rem;
    z-index: 1;
}
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
  }
  
  .switch input, .snap-switch input{ 
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider.round {
    border-radius: 24px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }

  .imaged{
      height: 100%!important;
      width: 100%!important;
      object-fit: cover!important;
      border-radius: 5px;
  }
  

/*media css*/
@media screen and (max-width:639px){
    .appCapsule {
        width:100vw;
    }
    .header-img img{
        width:100%;
    }
    .profile-section{
        height: 120px!important;
        background-size: 100%!important;
    }
    .profile-img{
        width:75px!important;
        height: 75px!important;
        object-fit: cover!important;
    }
    .appHeader{
        width:100vw!important;
    }
    .app-header{
        margin-top: 0px;
    }
    .imagedfill{
        width: 100%!important;
        height: 100%!important;
    }
    .imaged {
        height: auto;
        border-radius: 6px; 
        height:100%!important;
        width:100%!important;
    }
    /* .imaged.w-100{
        height: 100%!important;
        width: 100%!important;
        object-fit: cover;
    } */
} 

@media only screen and (min-width:640px) and (max-width:900px){
   /*  .profile-section{
        height: 165px!important;
        background-size: 100%!important;
    } */
    .profile-img{
        width: 110px !important;
        height: 110px!important;
        object-fit: cover!important;
    }
    .appHeader{
        width:100vw!important;
    }
    .imagedfill{
        width: 100%!important;
        height: 100%!important;
    }
    .imaged {
        height: auto;
        border-radius: 6px; 
        height:100%!important;
        width:100%!important;
    }
    /* .imaged.w-100{
        height: 100%!important;
        width: 100%!important;
        object-fit: cover;
    } */
}

.upload-image-wrapper-class{
    margin:auto;
    padding-top: 10px;
    width: 70%;
}

.uploader__container{
    margin: auto!important;
}

.appHeader .pageTitle{
    width: 50%;
}

.lens-request-image{
    max-height: 50vh;
}


.social-share-button-imgs {
    width: 1rem !important;
    height : 1rem !important;
    margin: auto 20px auto 5px;
}
.whatsapp-baner-share-img{
    max-width: 300px;
    width: 80%;
}