.profileimg{
    text-align:center;

}
.imgprofile{
    width: 130px!important;
    height:130px!important;
    border-radius: 50%;
    object-fit: cover!important;
}   
.close{
   margin-left: 90%;
}