/* Slider */
.slick-slider
{
    position: relative;
    overflow: hidden;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;
    width: 98vw;
    display: block;
    overflow: hidden;

    margin: 0;
    margin-bottom: 20px;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;
    width: 50000px !important;
    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
    height: 50vh !important;
    width: auto !important;
    outline: 1px solid #cacaca;
    outline-offset: -1px;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-next{
    top: 180px;
    right: 0;
    margin-right: 20px;
}
.my-image{
    margin-right: 20px;
}
.slick-next, .slick-prev {
    font-size: 0;
    line-height: 0;
    /* top: 50%; */
    width: 20px;
    height: 20px;
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
    position: absolute;
}
.slick-arrow .slick-hidden {
    display: none;
}
.slick-arrow {
    z-index: 7;
    font-size: 35px;
    color: white;
}
.prev::before{
    content: '\2039';
    justify-content: center;
    align-items: center;
    font-size: 3.2em;
    color: white;
    opacity: 1 !important;
}
.next::before{
    content: '\203a';
    justify-content: center;
    align-items: center;
    font-size: 3.2em;
    color: white;
    opacity: 1 !important;
}
.slick-next:before, .slick-prev:before{
    line-height: normal;
}
.slick-next, .slick-prev {
    height: auto;
}
.slick-prev{
    top: 180px;
    left: 0px;
    opacity: 1;
}
.list-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
}