.brandHeading {
    background-color: rgb(81, 81, 82);
    padding: 20px;
    text-align: center;
}

.brands-body-wrapper {
    margin: 0 0 100px 0;
    padding: 50px;
}

.brandHeading>h2 {
    color: white;
    text-shadow: 1px 3px 5px rgb(151, 151, 151);
    font-size: 30px;
}

.side-bar {
    width: 30%;
    padding-top: 100px;
    padding: 50px;
    border: 1px;
    margin: 0;
    display: inline;
    float: left;
}

.side-bar>input {
    margin-bottom: 1rem;
}

.side-bar>select {
    margin-bottom: 1rem;
}


.brandspage {
    width: 80%;
    margin: 0 10%;
}

.brandspage>h2 {
    text-align: center;
    color: rgb(90, 90, 90);
    text-shadow: 1px 1px 2px rgb(214, 154, 154);
    padding: 0 0 0 2rem;
    font-size: 36px;
    font-weight: 800;
    position: relative;
}

.brandspage>h2>select {
    margin-left: 5rem;
    font-size: 10px;
    border: none;
    background-color: none;
    font-weight: 600;
    padding: 4px;
    box-shadow: 0px 0 2px;
    border-radius: 3px;
    float: right;
}




.cat-name {
    margin: 20px 100px 20px 10px;
}

.category-count {
    box-shadow: 0px 0px 3px black;
    padding: 10px;
}

.right-float-counts-brands {
    float: right;
}

.cat-total-count {
    margin: 0 1rem;
}


.message-box-brandspage {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(82, 82, 82, 0.24);
}

.password-wrapper {
    background-color: rgb(185, 185, 185);
    width: vw;
    height: vh;
    margin: 0;
    padding: 100px;
}

.password-box {
    color: white;
    margin: 100px;
    border: 2px solid rgb(94, 94, 94);
    padding: 50px;
    border-radius: 10px;
    background-color: rgb(32, 36, 48);
}

.password-box>input,
#admin-password {
    color: white;
    background-color: rgba(134, 134, 134, 0.253);
    outline: none;
    border: 2px solid rgba(196, 196, 196, 0);
    border-radius: 3px;
    padding: 3px;
    margin: 5px;
    margin-bottom: 20px;
    align-self: center;
}

.password-box>input:focus,
#admin-password:focus {
    font-weight: 500;
    border: 2px solid rgba(133, 133, 133, 0.4);
}

.password-box>button {
    color: white;
    background-color: rgb(119, 119, 119);
    border: none;
    padding: 5px 30px;
    border-radius: 50px;
    font-weight: 400;
}

.password-box>button:hover {
    background-color: rgb(112, 112, 112);
    box-shadow: 0 0 1px;
}




.switch {
    position: relative;
    top:0.3em;
    display: inline-block;
    width: 1.4em;
    height: 0.8em;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 0.6em;
    width: 0.6em;
    left: 0.1em;
    bottom:0.08em;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.swtch input:checked+.slider {
    background-color: rgb(85, 243, 85);
}

.switch>input:focus+.slider {
    box-shadow: 0 0 0px rgb(58, 58, 58);
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(4em);
    -ms-transform: translateX(4em);
    transform: translateX(0.6em);
}

/* Rounded sliders */
.slider.round {
    border-radius: 1em;
}

.slider.round:before {
    border-radius: 50%;
}