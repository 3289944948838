.accordion-button{
    display: block !important;;
    text-align: center!important;
}
img{
    width:100%!important;
    object-fit: contain!important;
}
.faqscss
{
    border: 1px solid rgba(235,217,204,.125);
    background-color: #fff2e4;
    font-family:'Poppins';
    text-align: center!important;
}
.header{
    background-color: white;
}
.header-left > img{
    width: 65%;
}
.header-right-up > img{
    margin-top: 50px;
    width: 50%;
    margin-left:20%;
}
.header-right-mid{
    width: 60%;
    margin-left: 20%;
}
.header-right-down{
    width: 50%;
    margin-left: 25%;
    cursor: pointer;
}
.intro{
    background-color: #ebd9cc;
    text-align: center;
}
.intro > img{
    width: 70%
}
.old-vs-new{
    background-color: #fff2e4;
    text-align: center;
}
.old-vs-new > img{
    width:95%;
}
.partner-profit{
    background: #ebd9cc;
}
.profit-margin > img{
    width: 70%;
    margin-left: 10%;
}
.partners{
    width: 78%;
    margin-left: 5%;
}
.faq{
    text-align: center;
    background-color: #fff2e4;
}
.faq > img{
    width: 100%;
}
.faqs
{
    text-align: center;
    background-color: #fff2e4;
}
.faqs > img{
    width: 100%!important;
}
.toggleclass
{
    text-align: center!important;
}
.faq-question{
    background-color: #fff2e4;
}
.questions{
    width:100%;
    text-align: center;
}
.questions > img{
    width: 60%;
}
.footer{
    background-color: black;
    text-align: center  ;
}
.footer img{
    width: 95%;
}
body{
    background-color: white;
}
p {
    width:  50%;
    margin: auto;
    font-family: 'Poppins', sans-serif;
  }
.imgforce
{
    width:100%;
}
.address{
    font-family:'Poppins'!important;
    font-size: 1em!important;
}
.button{
    padding: 0;
    border: none;
    background: none;
}