body {
  margin: 0;
  padding: 0;
  height:1000px;
 }
 .App{
  color: #592BCA;
  text-align: center;
 }
 
 .appfooter .left {
  left: 8px;
  top: 0; }
.appfooter .right {
  right: 8px;
  top: 0; }

.appfooter.no-border {
    border: 0;
    box-shadow: none; }
.appfooter.transparent {
    background: transparent;
    box-shadow: none; }
.appfooter .left,
.appfooter .right {
    height: 56px;
    display: flex;
    align-items: center;
    position: absolute; }
    .appfooter .left i.bi,
    .appfooter .left i.icon,
    .appfooter .left ion-icon,
    .appfooter .right i.bi,
    .appfooter .right i.icon,
    .appfooter .right ion-icon {
      font-size: 26px;
      --ionicon-stroke-width: 36px; }
.appfooter .left .headerButton,
.appfooter .right .headerButton {
      min-width: 36px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      color: #141515;
      position: relative; }
.appfooter .left .headerButton .btn,
.appfooter .right .headerButton .btn {
        font-size: 16px;
        height: 32px;
        padding: 0px 14px;
        font-weight: 400; }
.appfooter .left .headerButton:active,
.appfooter .right .headerButton:active {
        opacity: 0.6; }
.appfooter .left {
    left: 8px;
    top: 0; }
.appfooter .right {
    right: 8px;
    top: 0; }
.appfooter .pageTitle {
    font-size: 17px;
    font-weight: 500;
    padding: 0 10px; }
.appfooter .pageTitle .logo {
      max-height: 18px; }
.appfooter.text-light {
    color: #fff; }
.appfooter.text-light .headerButton,
.appfooter.text-light .pageTitle {
      color: #fff; }
.appfooter.bg-primary, .appfooter.bg-secondary, .appfooter.bg-success, .appfooter.bg-warning, .appfooter.bg-danger, .appfooter.bg-info, .appfooter.bg-light, .appfooter.bg-dark {
    border: 0; }

.appfooter.scrolled {
      background: transparent;
      border-bottom: 1px solid transparent;
      box-shadow: none;
      height: 0;
      transition: 0.1s all; }
.appfooter.scrolled .pageTitle {
        opacity: 0; }
.appfooter.scrolled.is-active {
        background: #FFF;
        min-height: 56px;
        border-bottom-color: #E1E1E1;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08); }
.appfooter.scrolled.is-active .pageTitle {
          opacity: 1; }
.appfooter.scrolled.bg-info {
      background: transparent !important;
      border-bottom: 0 !important; }
.appfooter.scrolled.bg-info .headerButton {
        color: #592BCA; }
.appfooter.scrolled.bg-info.is-active {
        background: #592BCA !important; }
.appfooter.scrolled.bg-info.is-active .headerButton {
          color: #FFF !important; }
    
.appfooter.scrolled.bg-dark {
      background: transparent !important;
      border-bottom: 0 !important; }
.appfooter.scrolled.bg-dark .headerButton {
        color: #000; }
.appfooter.scrolled.bg-dark.is-active {
        background: #000 !important; }
.appfooter.scrolled.bg-dark.is-active .headerButton {
          color: #FFF !important; }
.demoPage .appfooter {
            position: relative;
            z-index: 0; }
.demoPage .appfooter:before {
              height: 0 !important;
              display: none; }
.appfooter .badge {
                min-width: 16px;
                height: 16px;
                line-height: 9px !important;
                font-size: 10px;
                padding: 0 4px !important;
                position: absolute;
                right: 2px;
                top: 10px; }
              
.appfooter .badge-empty {
                top: 14px; }
.appfooter .badge {
                  min-width: 16px;
                  height: 16px;
                  line-height: 9px !important;
                  font-size: 10px;
                  padding: 0 4px !important;
                  position: absolute;
                  right: 2px;
                  top: 10px; }
body.dark-mode-active .appfooter {
                    background: #0F1C2F;
                    border-bottom-color: #1B283B; }
body.dark-mode-active .appfooter .pageTitle {
                      color: #FFFFFF; }
body.dark-mode-active .appfooter.transparent {
                      background: transparent !important; }
body.dark-mode-active .appfooter.bg-light .left .headerButton,
body.dark-mode-active .appfooter.bg-light .right .headerButton {
                      color: #141515; }
body.dark-mode-active .appfooter.bg-light .pageTitle {
                      color: #141515; }
body.dark-mode-active .appfooter .left .headerButton,
body.dark-mode-active .appfooter .right .headerButton {
                      color: #FFFFFF; }
body.dark-mode-active .extraHeader {
                    background: #0F1C2F;
                    border-bottom-color: #1B283B; }
body.dark-mode-active .appfooter.scrolled {
                    background: transparent;
                    border-bottom-color: transparent; }
body.dark-mode-active .appfooter.scrolled.is-active {
                      background: #0F1C2F;
                      border-bottom-color: #1B283B; }   
.fixed-footer {
  position: fixed;
bottom: 0;
left: 0;
min-height: 84px;
right: 0;
background: #fff;
display: flex;
align-items: center;
justify-content: center;
padding-left: 8px;
padding-right: 8px;
padding-bottom: env(safe-area-inset-bottom); }
.fixed-footer > div {
     width: 100%; }
.fixed-footer.transparent {
    background: transparent !important; }