.image{
    width: 100%;
}

.option{
    width: 100%;
    display: inline-block;
    text-decoration: none;
    color: #424553;
    letter-spacing: 1px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
}
.option_container{
    text-align: left;
    font-size: 13px;    
    padding: 9px 10px;
    border: 1px solid #e1e1e1;
}
.insta_container{
    text-align: center;
    padding: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #424553;
}
.insta_image{
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block !important;
    height: 20px !important;
}
