.app-header
{
    text-align: center;
}
.appCapsule {
    width:70vw;
    padding: 56px 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: env(safe-area-inset-bottom);
    margin-top: env(safe-area-inset-top); 
    overflow: hidden;
}
ul {
    list-style-type: none;
}
.text-muted-11{
    margin-top:8%;
    font-size: 1em;
    color: #A1A1A2 !important;
}
.text-muted-11-5 {
    margin-top:8%;
    font-size: 1em;
    color: #A1A1A2 !important;
}
.cross-sell-title{
    color:black;
    text-align: left!important;
    font-size:12px!important;
    padding-left: 2%!important;
}
.cross{
    margin-top: 1%;
}
.showToPublic{
    /* background-color: #eaf2f8; */
    border-radius: 10px;
}
.visibilityOff{
    /* background-color: grey; */
    border-radius: 10px;
    opacity:0.25;
}
.newdesign{
margin-left: -8px!important;
margin-right: -8px!important;
margin-bottom: 5px!important;
padding-top: 5px!important;
}
.product-modal{
    margin-top:25vh;
    width:100vw;
}
.product-modal .modal-dialog{
    margin: 0;

}
.product-modal .modal-content{
    background: transparent;
    border: none;
}
.product-modal .modal-body{
    height:65vh;
    background: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.product-modal .modal-header{
    border-bottom: none;
}
.google-login-modal {
    margin-top:55vh !important;
}
.google-login-modal .modal-body{
    padding-top: 3rem;
    height:40vh !important;
}
.google-login-modal .google-login{
    padding-top: 2rem;
    display: flex;
    align-content: center;
}
.google-login-modal .google-login button{
    margin: 0 auto;
}
.imaged{
    height: 100%!important;
    width: 100%!important;
    object-fit: cover!important;
}
.imagednew{
    height: 60px;
    border-radius: 6px!important;
}
.imagedbox
{
    height:100%!important;
    width:100%!important;
    object-fit: cover;
}
.modalimaged{
    height: 260px!important;
    width: 100%!important;
    object-fit: contain!important;
}
.toggle-crossSell{
    position: absolute;
    top:8px;
    right: 20px;
    cursor: pointer;
}
.greyscale{
    width: 90% !important;
    height: 90%!important;
    filter: grayscale(100%)!important;
    opacity: 0.15;
   
}
.crossSell-product-visibility{
    z-index: 2000!important;
}
.cross-greyscale
{
    filter: grayscale(100%);

}
/* .custom-modal-body{
    overflow-y: scroll;
}
.scrollable{
    overflow: scroll;
} */
.product-modal .modal-body::-webkit-scrollbar {
    -webkit-appearance: none;
    appearance: none;
    width: 5px;
  }
  
  .product-modal  .modal-body::-webkit-scrollbar-thumb {
    border-top-right-radius: 25px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.btn-light-unsave{
    background-color: aliceblue;
    border: none;
    color: black;
}

.h64{
    height: 64px !important;
}
.save-unsave-btn{
    box-shadow: 0 0 2px black !important;
    font-weight: 500;
}
@media only screen and (max-width:812px)
{
    .appCapsule {
        width:100vw;
        overflow: hidden;
    }
    .product-preview{
        height: 50px;
    }
    .cross{
        margin-top: 12%;
    }
    .text-muted-11{
        margin-top:0px;
        font-size:10px;
    }
    .text-muted-11-5{
        margin-top:0px;
        font: size 15px;
    }
    .off
    {
        opacity: 0.15;
    }
    .modalimaged{
        height: 235px!important;
        width: 100%!important;
        object-fit: contain!important;
    }
    .imagedbox
    {
        height:100%!important;
        width:100%!important;
    }
    .imagednew
    {
        height:60px!important;
        border-radius: 6px!important;
    }
    .save-unsave-btn{
        font-weight: 700;
        letter-spacing: 1px;
    }
}
@media only screen and (min-width: 864px){
    .product-modal{
        left: 15% !important;
    }
    .product-modal .modal-content{
        background: transparent;
        border: none;
        width:70vw;
    }
} 

.cart{
    position: absolute;
    bottom:10px;
    right: 15px;
    cursor: pointer;
}
.cart1{
    position: absolute;
    bottom: 3.5%;
    left: 1%;
    cursor: pointer;
}
.street_style{
    position: relative;
}

.st-btn.st-first.st-last img{
    width: 20px!important;
}

.horizontal-buttons-div{
    overflow-x: auto;
    white-space: nowrap;
}

.my-looks-product-modal{
    margin-top: 0!important;
}

.my-looks-product-modal .modal-header{
    padding-top: 29vh;
}

.my-looks-product-modal .modal-body{
    height: 70vh;
}

.reduced-paddings{
    padding-left: 3px!important;
    padding-right: 3px!important;
}