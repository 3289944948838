.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.product_wrapper {
  margin: 3px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.product_desc {
  font-family: monospace;
  color: #189c92;
  padding-left: 30px;
}

.pagination_wrapper {
  /* display: inline-block;
  text-align: center; */
  margin: 0 auto;
  display: table;
  padding-top: 15px;
}

.pagination {

  display: flex;
  text-decoration: none;
  padding: 0px;
}

.pagination a {
  color: #189c92;
  text-decoration: none;
}

.pagination li {
  margin: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  border-collapse: collapse;
  font-family: monospace;
  background-color: #fff;
  padding: 15px;
  list-style: none;
  border: 2px solid #eee;
  list-style: none;
}

a {
  text-decoration: none;
}

.pagination .active {
  background-color: #189c92;
}

.pagination .active a {
  color: white;
}

.iMLKlc {
  padding-top: 1%;
  display: inline-block !important;
  text-align: center;
}

.ftSrFl {
  display: inline-block !important;
  text-align: center;
}

.wrapper_link {
  width: 100%;
}

img {
  object-fit: contain;
  width: 100%;
  /* height: 275px; */
}

.left_align {
  width: 18% !important;
  float: left !important;
}

.product_name {
  margin: 0px;
  font-weight: 400;
  text-transform: uppercase;
  color: #282C3F;
}

.product_description {
  text-align: left;
  padding-left: 20px;
}

.product_width {
  width: 15% !important;
  padding: 10px 10px;
}
/* 
.row {
  font-size: 14px;
  display: inline-block;
  /* width: 100%; */
  /* margin: 25px 0px; */


.col {
  text-align: center;
  width: 25%;
  float: left;

}

.firstRow {
  width: 40% !important;
  float: left;

}

.restRow {
  width: 33% !important;
  float: left;
}

.functionRow {
  padding-left: 35px;
  width: 20% !important;
  float: left;
}

.product_id {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  color: #535665;
  margin-top: 0px;
  letter-spacing: 1px;
  margin-bottom: 0px;
  padding: 5px 0 6px 0px;
  font-size: 1.2em;
  opacity: 0.8;
  font-weight: 400;
}

.title {
  color: #535665;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 5px 20px 14px 0px;
  font-size: 18px;
  opacity: 0.8;
  font-weight: 400;
  text-align: left
}

.col_wrapper {
  /* margin-top: 66px; */
  border-bottom: 2px solid lightgray;
  padding-left: 10px;
  padding-top: 15px;
  display: inline-block;
}

.main_wrapper {

  border: 1px solid rgb(238, 238, 238);

}

.container_review {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container_review input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.container_review .checkmark {

  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container_review:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container_review input:checked~.checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container_review .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container_review input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container_review .checkmark:after {
  top: 7px;
  left: 7px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}



.container_radio {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container_radio input {

  /* position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; */
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin: 0px;
  bottom: -22px;
  left: 45%;
  height: 25px;
  width: 25px;
  background-color: #eee;
  z-index: 5;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  bottom: -22px;
  left: 45%;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container_radio:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container_radio input:checked~.checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container_radio input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container_radio .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.heading {
  letter-spacing: 2px;
  margin: 0px;
  padding: 0px 30px;
  color: white;
  font-weight: 600;
  font-size: 1em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar {
  position: fixed;
  width: 100%;
  z-index: 3;
  background-color: #409EFF;
  height: 60px;
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.1);
}
.el-input__inner{
  border: 1px solid #fff !important;
  font-size: 18px;
}
.el-select-dropdown{
  border: none !important;
}
.el-select-dropdown.is-multiple, .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
  /* background-color: #f5a6b15c !important; */
}
.el-select-dropdown__item.selected{
  color: white !important;
  /* background-color: #f5a6b1b5 !important; */
}
.el-select-dropdown__item{
  display: flex;
  align-items: center;
}
.el-select-dropdown__item span{
  height: auto !important;
}
/* .my-pagination .el-pager li.active{
  border-color: #f5a6b18c;
  background-color: #f5a6b1b6;
  color: white;
} 
.my-pagination .el-pager li :hover{
  color: #f5a6b1b6;
} */
.my-pagination .el-pagination__editor{
  margin-left: 5px;
  /* border: 1px solid #f5a6b1b5; */
}
.my-alert{
  padding: 20px;
}
.my-alert .el-alert--error{
  color: #000;
  background-color: #40a0ff4d;
}
.my-alert .el-alert__title{
  font-size: 20px;
  line-height: 32px;
}
::placeholder{
  color: black !important;
}
.function_icon_wrapper {
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  left: 0;
  bottom: 6px;
  width: 100%;
}


.image_wrapper {
  margin: 15px 0px;
  position: relative;
  /* padding: 1px 3px 15px 3px; */
}

.fa {
  cursor: pointer;
  font-size: 20px;
}

.icon_refresh {
  margin-left: calc(50% - 13px);
}

.storeBtn {
  z-index: 1;
  left: 5px;
  position: fixed;
  top: 18px;
  width: 150px;
  height: 45px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 600;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 3px 8px #6c757d;
}

.id_wrapper {
  color: cadetblue;
  padding: 10px;
}

.wrapper_loading {
  height: 90vh;
  width: 100vw;
  /* background-color: #ccc; */

}

.link_wrapper {
  text-decoration: underline;
  text-align: center;

  display: inline-block;
  margin: 10px 10px 0px 0px;

  margin: 20px;
  border-radius: 10px;
  background-color: #FFFFFF;

  padding: 6px 1px;


}

/* .crop_heading{
  position: absolute;
  top: 0px;
  left: 5px;
  color:#189c9291;
} */
.form_input {
  text-align: left;
}

.link_to_page {
  margin-top: 0px;
  margin-bottom: 0px;
  opacity: 0.8;
  font-weight: 400;
  text-align: center;
  font-size: 1em;
  opacity: 0.8;

  font-weight: 600;

}

.loading {
  position: fixed;
  top: 45%;
  font-size: 3em;
  left: 48%;
}

.fa-spin {

  color: #418b96;
  ;
}

/* Customize the label (the container) */
.container_radio_check {
  font-family: roboto;
  float: left;
  color: #535665;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 1.2em;
  opacity: 0.8;
  font-weight: 400;
  padding: 2px 20px 14px 50px;
  opacity: 0.8;
  font-weight: 400;
  text-align: left;
  display: block;
  position: relative;
  padding-left: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container_radio_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.container_radio_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container_radio_check:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container_radio_check input:checked~.checkmark {
  background-color: #418b96;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container_radio_check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container_radio_check input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container_radio_check .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.gXlqCi {
  margin-top: 60px;
  min-height: 0px !important;
  padding: 0px !important
}

.gAUsQw {
  z-index: 4;
  ;
  position: fixed;
  top: 12px;
  right: 20px;
}

.jJknLL {
  padding: 0px !important;

}

.jIaJrt {
  background: #e3f2fd;
}

.jtnqNO {
  padding: 0px !important;
}

h3 {
  margin-left: 15px;
}

.fileInput {
  border-radius: 5px;
  border: 1px solid #D4D5D9;
  -webkit-box-shadow: 0px;
  -moz-box-shadow: 0px;
  box-shadow: 0px;
  padding: 10px;
  font-size: 1em;
  min-width: 160px;
  outline: 0;
  width: 250px;
  font-family: monospace;
  padding: 10px;
  margin: 0px 15px 0px 0px;
  cursor: pointer;
}

.previewComponent {
  padding-top: 70px;
}

.imgPreview {
  text-align: center;
  margin: 15px 15px;
  height: 200px;
  width: 95%;
  box-shadow: 10px 10px 8px #888888;
}

.previewComponent img {
  width: 100%;
  height: 100%;
}

.previewText {
  width: 100%;
  font-family: monospace;
}

.submitButton:focus {
  outline: none;
}

.submitButton {
  color: #189c92;
  outline: 0;
  border: 1px solid #418b96;
  padding: 11px;
  font-family: monospace;
  /* border: none; */
  background-color: white;
  border-radius: 17px;
  font-weight: 500;
  font-size: 10pt;
  cursor: pointer;

}

.submitButton:hover {
  background-color: #418b96;
  color: white
}

.sorting_wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
}

.centerText {
  text-align: center;
  width: 500px;
}

.delete_icon {
  color: #3f4257;

}

.delete_icon:hover {
  color: black;
  font-size: 1.5em;
}

.delete_icon:active {
  color: #418b96;

}

.align_images {
  display: flex;
  flex-wrap: wrap
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.show-more-button-row {
  padding: 40px 0px;
  text-align: center;
}

.my-pagination {
  height: 50px;
  border-top: 0.4px solid #409EFF;
  border-bottom: 0.4px solid #409EFF;
  align-items: center;
  display: flex;
  justify-content: space-around;
}
.el-pagination button, 
.el-pagination span, .el-pager li, 
.el-pagination__sizes .el-input .el-input__inner, 
.el-pagination__editor {
  font-size: 16px !important;
}
.el-pagination__total{
  font-weight: bold !important;
}
.products-list {
  /* height: 90vh; */
  width: 95%;
  display: flex;

  flex-flow: row wrap;
  /* justify-content: space-around; */
}

.products-container {
  padding-top: 60px;
}

.product-img-container {
  /* width: 16.66%; */
  display:block;
  width: calc(calc(100% - 60px));
  margin: 15px auto;
}

.product-image {}

.product-image img {
  max-height: 100%;
  max-width:100%;
  object-fit: contain;
}

.product-id {
  text-align: center;
  padding: 5px 0;
  letter-spacing: 1px;
  color: #282c34;
}

.my-title{
  color: #2d2d2d;
  margin-top: 10px;
  margin-bottom: 0px;
  padding: 0 20px 0 0;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 1.1;
  letter-spacing: 2.8px;
  line-height: 1.3;
  letter-spacing: 1.3px;
  font-family: "futura-pt-n4","futura-pt",Tahoma,Geneva,Verdana,Arial,sans-serif;
}
.my-subtitle{
  font-weight: 400;
  color: #ff3e6c;
  margin-top: 0px;
  font-size: 10px;
  letter-spacing: .5px;
}
/* 
@media (max-width: 600px){
  .product-container {
    width: calc((100% - 32px)/2);
  } 
} */

.option_container a{
  color: #000;
}
.disabled{
  cursor: not-allowed;
  opacity: 0.5;
}
.next{
  text-align: center;
}
.next a{
  color:palevioletred;
}
.storeTitle{
  padding-top: 80px;
  margin: auto;
  color: #424553;
  letter-spacing: 1px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
}
@media only screen and (max-width: 740px) {
  .product-img-container {
    /* width: 16.66%; */
    display:block;
    padding: 10px;
    width:calc(calc(100% - 20px));
  }
  .storeTitle{
    padding-top: 80px;
    color: #424553;
    letter-spacing: 1px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
    display: -webkit-flex;
    display: flex;
  }
}
